var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"_bg-default _80w _100vh pb-3 px-3"},[(_vm.feedback)?_c('div',{staticClass:"_100top"},[_c('section',{staticClass:"mb-5 mt-5"},[_c('h3',{staticClass:"mb-n3"},[_vm._v("Feedback")]),_c('v-divider',{attrs:{"inset":""}})],1),_vm._l((_vm.feedback.data),function(item){return _c('div',{key:item.id},[_c('v-card',{staticClass:"my-3 pa-3",attrs:{"color":"white"}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mr-3"},[_c('v-btn',{attrs:{"fab":"","depressed":"","color":item.judul == 'Saran'
                    ? 'blue'
                    : item.judul == 'Lainnya'
                    ? 'green'
                    : item.judul == 'Bug'
                    ? 'red'
                    : 'orange',"dark":""}},[(item.judul == 'Saran')?_c('v-icon',[_vm._v("mdi-comment-quote")]):_vm._e(),(item.judul == 'Lainnya')?_c('v-icon',[_vm._v("mdi-note")]):_vm._e(),(item.judul == 'Bug')?_c('v-icon',[_vm._v("mdi-alert")]):_vm._e()],1)],1),_c('div',[_c('section',[_c('h3',[_vm._v(_vm._s(item.judul))]),_vm._v(" "+_vm._s(item.isi)+" ")]),_c('section',[_c('small',[_c('b',{staticClass:"blue--text"},[_vm._v(" "+_vm._s(_vm.$date(item.created_at).fromNow())+" ")])])])])]),_c('div',[_c('v-btn',{directives:[{name:"tippy",rawName:"v-tippy",value:({
                arrow: true,
                arrowType: 'round',
                animation: 'fade',
                theme: 'light',
                maxWidth: 150
              }),expression:"{\n                arrow: true,\n                arrowType: 'round',\n                animation: 'fade',\n                theme: 'light',\n                maxWidth: 150\n              }"}],attrs:{"fab":"","depressed":"","color":"red","dark":"","x-small":"","content":"Delete"},on:{"click":function($event){return _vm.deleteItem(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)])])],1)}),(_vm.feedback)?_c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(_vm.handleScroll),expression:"handleScroll"}]}):_vm._e(),(!_vm.feedback.data.length)?_c('div',{staticClass:"d-flex justify-center"},[_vm._m(0)]):_vm._e()],2):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"pa-2"},[_c('h3',{staticClass:"text-center"},[_vm._v("No Feedback")])])}]

export { render, staticRenderFns }