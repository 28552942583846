<template>
  <div class="_bg-default _80w _100vh pb-3 px-3">
    <div v-if="feedback" class="_100top">
      <section class="mb-5 mt-5">
        <h3 class="mb-n3">Feedback</h3>
        <v-divider inset></v-divider>
      </section>
      <div v-for="item in feedback.data" :key="item.id">
        <v-card class="my-3 pa-3" color="white">
          <div class="d-flex justify-space-between">
            <div class="d-flex">
              <div class="mr-3">
                <v-btn
                  fab
                  depressed
                  :color="
                    item.judul == 'Saran'
                      ? 'blue'
                      : item.judul == 'Lainnya'
                      ? 'green'
                      : item.judul == 'Bug'
                      ? 'red'
                      : 'orange'
                  "
                  dark
                >
                  <v-icon v-if="item.judul == 'Saran'"
                    >mdi-comment-quote</v-icon
                  >
                  <v-icon v-if="item.judul == 'Lainnya'">mdi-note</v-icon>
                  <v-icon v-if="item.judul == 'Bug'">mdi-alert</v-icon>
                </v-btn>
              </div>
              <div>
                <section>
                  <h3>{{ item.judul }}</h3>
                  {{ item.isi }}
                </section>
                <section>
                  <small
                    ><b class="blue--text">
                      {{ $date(item.created_at).fromNow() }}
                    </b></small
                  >
                </section>
              </div>
            </div>
            <div>
              <v-btn
                fab
                depressed
                color="red"
                dark
                x-small
                content="Delete"
                v-tippy="{
                  arrow: true,
                  arrowType: 'round',
                  animation: 'fade',
                  theme: 'light',
                  maxWidth: 150
                }"
                @click="deleteItem(item.id)"
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </div>
          </div>
        </v-card>
      </div>
      <div v-if="feedback" v-observe-visibility="handleScroll"></div>

      <div v-if="!feedback.data.length" class="d-flex justify-center">
        <section class="pa-2">
          <h3 class="text-center">No Feedback</h3>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";
export default {
  name: "listFeedback",

  data() {
    return {
      currentPage: 1,
      limit: 10
    };
  },
  computed: {
    ...mapState({
      feedback: state => state.feedback
    })
  },
  mounted() {
    this.fetchItem();
  },
  methods: {
    fetchItem() {
      this.$store.dispatch("listFeedback");
    },
    deleteItem(item) {
      Swal.fire({
        title: "Are You Sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        position: "top",
        toast: true
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch("deleteFeedback", { id: item }).then(data => {
            this.fetchItem(); //if success refetch data all
            Swal.fire({
              title: data.message,
              icon: "success",
              showConfirmButton: false,
              timer: 1000,
              position: "top",
              toast: true
            });
          });
        }
      });
    },
    handleScroll(isVisible) {
      if (isVisible) {
        this.currentPage++;
        this.fetchItem();
        // if (this.currentPage <= this.last) {
        // }
      }
    }
  }
};
</script>

<style scoped>
.__overflow__hidden {
  overflow: hidden !important;
  box-sizing: border-box;
}
.__template__cover {
  width: 100%;
  height: 230px;
  background: #f3e5f5;
}
.__relative {
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
}
.cover__ {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
